import { useEffect } from 'react'

import { OffersTemplate } from '@itau-loans-www/templates'
import analytics from '@mobi/libraries/analytics'

const Offers = () => {
  useEffect(() => {
    analytics.track('pageLoad', { currentPage: 'vitrine-shopping' })
  }, [])

  return <OffersTemplate />
}

export default Offers
